import { graphql } from 'gatsby'
import React from 'react'

import { LargeTeaser, LargeTeaserView, Layout, Section, SEO } from '../components'
import { contentColorMap } from '../styles'
import { AllMarkdown } from '../types'

interface EventsPageProps {
  data: { allMarkdownRemark: AllMarkdown }
  location: Location
}

export const EventsPage = ({
  location: { pathname },
  data: { allMarkdownRemark }
}: EventsPageProps) => {
  const pageColor = contentColorMap.events
  const title = 'Termine'
  return (
    <Layout currPath={pathname} pageColor={pageColor}>
      <SEO title={title} />
      <Section index={0} color={pageColor} headerOffset={true} renderBg={true}>
        <LargeTeaserView title={title}>
          {allMarkdownRemark.edges.map(
            (
              {
                node: {
                  fields: { slug },
                  frontmatter: { summary, image, title, date, location }
                }
              },
              i
            ) => (
              <LargeTeaser
                key={i}
                image={image!}
                path={slug!}
                title={title!}
                summary={summary!}
                prefix={[date]}
                suffix={[location]}
                index={i}
              />
            )
          )}
        </LargeTeaserView>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query Events {
    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date, frontmatter___title]}, filter: {fileAbsolutePath: {regex: "/(events)/.*\\.md$/"}, fields: { hidden: { eq: false } } }) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            summary
            title
            date(formatString: "D.M.YYYY")
            location
            image {
              mobile: childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 700, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
              desktop: childImageSharp {
                fluid(maxWidth: 600, maxHeight: 420, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventsPage
